
import React, { useEffect, useState } from "react";
import './styles.css'
import AOS from 'aos';

function Card({cardTitle, typesApp}) {

    useEffect(() => {
        AOS.init({
          duration: 1000,
        });
      }, []);

    return(
        <div>
            <aside data-aos="fade-left">
                <h3>{cardTitle}</h3>
                <article className="article-card">
                    {typesApp.map((item, index) => (
                        <div className="card" key={index}>
                            {item.headerTitle == "" && (
                                <div className="card-title">
                                    <h4>{item.headerTitle}</h4>
                                </div>
                            )}
                            <div className="card-header">
                                <img src={item.img} alt={item.altImg} style={item.imgStyle}/>
                            </div>
                            <div className="card-text">
                                <h4>{item.textTitle}</h4>
                                <p>{item.text}</p>
                            </div>
                        </div>
                    ))}
                </article>
            </aside>
        </div>
    )
}

export default Card;