
import React, { useEffect } from "react";
import '../../assets/styles/global.css';
import javascriptLogo from '../../assets/images/logo-javascript.png';
import './styles.css'
import AOS from 'aos';

function ServiceDetails({ explandServiceTile, explandServiceDescription, serviceNeedsTitle, serviceNeedsDescription0, serviceNeedsDescription1, imageBg}) {

    useEffect(() => {
        AOS.init({
          duration: 1000,
        });
      }, []);


    return(
        <div className="container" data-aos="fade-down">
            <aside>
                <h2>{explandServiceTile}</h2>
                <p>{explandServiceDescription}</p>
            </aside>
            <aside>
                <h2>{serviceNeedsTitle}</h2>
                <article className="display-flex width-100 display-block-1000">
                    <div className="padding-50">
                        <p>{serviceNeedsDescription0}</p>
                        <p>{serviceNeedsDescription1}</p>
                    </div>
                    <img className="padding-50 img-width-400" src={imageBg}/>
                </article>
            </aside>
        </div>
    )
}

export default ServiceDetails;