import Aos from "aos";
import React, { useEffect } from "react";

function CardInline({ card }) { 

    useEffect(() => {
        Aos.init({
          duration: 1000,
        });
      }, []);

    return (
        <div>
            <section>
                <aside className="display-block-1000 display-flex" data-aos="fade-left">
                    {card.map((item, key) => (
                        <article className="width-50">
                            <h3>{item.title}</h3>
                            <p>{item.description}</p>
                        </article>

                    ))}
                </aside>
            </section>
        </div>
    )
}

export default CardInline;