import React, { useEffect } from 'react';
import './styles.css';
import { useTranslation } from 'react-i18next';
import Aos from 'aos';


function LogoCarousel({logos}) {

    useEffect(() => {
        Aos.init({
          duration: 1000,
        });
      }, []);

    const {t} = useTranslation()
    return (
        <div className="logo-carousel" data-aos="fade-down">
            <h2>{t('our_experience')}</h2>
            <div className="logo-track">
                {logos.map((logo, index) => (
                    <div className="logo-item" key={index}>
                        <img src={logo.src} alt={logo.alt} />
                    </div>
                ))}
                {logos.map((logo, index) => (
                    <div className="logo-item" key={index + logos.length}>
                        <img src={logo.src} alt={logo.alt} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default LogoCarousel;
