
const config = {
    name: "Solution apoio",
    location: "Mirandela, Portugal",
    cellPhone: "(+351) 920 642 041",
    cellPhoneLink: "351920642041",
    emailGeral: "geral@solution-apoio.pt",
    emailInfo: "info@solution-apoio.pt",
    link : "www.solution-apoio.pt",

    facebookLink: "https://www.facebook.com/SolutionApoioOficial",
    whatsappLink: "https://api.whatsapp.com/send/?phone=351920642041",
    youtubeLink: "https://www.youtube.com/@solution-apoio",
    linkedinLink: "https://www.linkedin.com/company/solution-apoio",
    instagramLink: "https://www.instagram.com/solution.apoio",
    githubLink: "https://github.com/solution-apoio",
};

export default config;
