import React, {useEffect} from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import website from '../../assets/images/website.png';
import mobile_app from '../../assets/images/mobile_app.png';
import db from '../../assets/images/database-example.png'
import man_call from '../../assets/images/man-call.jpg';
import { useTranslation } from 'react-i18next';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './styles.css'; 


function Home() {

    const { t } = useTranslation();

    useEffect(() => {
        AOS.init({
          duration: 1000,
        });
      }, []);

    return (
        <div className="App">
            <Header pageName={t('home')} headerName={t('wait_for_you')} headerStyle="header-90" buttonName ={t('contact_us')} buttonLink="/contact-us" showButton={true} />
            
            <section>
                <aside data-aos="fade-down">
                    <h3>{t("who_we_are.title")}</h3>
                    <p className='text text-80'>{t("who_we_are.description.0.text")}</p>
                    <p className='text text-80'>{t("who_we_are.description.1.text")}</p>
                    <p className='text text-80'>{t("who_we_are.description.2.text")}</p>
                </aside>
                <aside className='bg-image' data-aos="fade-down">
                    <article className='itens-center'>
                        <h3>{t("we_are_specialists")}</h3>
                        <p className='text text-80'>{t("we_are_specialists_description")}</p>
                        <a className='button bt-primary' href='/services'>{t("learn_more")}</a>

                    </article>
                </aside>

                <aside className='aside-services'>
                    <article data-aos="fade-right">
                        <img src={website} />
                    </article>
                    <article className='itens-center' data-aos="fade-left">
                        <h3>{t("websites")}</h3>
                        <p className='text text-80'>{t("websites_description")}</p>
                        <a className='button bt-secondary' href='/services/website'>{t("learn_more")}</a>
                    </article>
                </aside>
                
                <aside className='aside-services background-primary text-white'>
                    <article className='aside-image' data-aos="fade-right">
                        <img src={mobile_app} />
                    </article>
                    <article className='itens-center' data-aos="fade-left">
                        <h3>{t("mobile_apps")}</h3>
                        <p className='text'>{t("mobile_apps_description")}</p>
                        <a className='button bt-primary' href='/services/mobile-app'>{t("learn_more")}</a>
                    </article>
                </aside>

                <aside className='aside-services'>
                    <article data-aos="fade-right">
                        <img src={db} />
                    </article>
                    <article className='itens-center' data-aos="fade-left">
                        <h3>{t("database_home.title")}</h3>
                        <p className='text'>{t("database_home.description")}</p>
                        <a className='button bt-secondary' href='/services/database'>{t("learn_more")}</a>
                    </article>
                </aside>

                <aside className='aside-call-man background-primary text-white'>
                    <article className='aside-image' data-aos="fade-right">
                        <img src={man_call} />
                    </article>
                    <article className='itens-center' data-aos="fade-left">
                        <h3>{t("we_help_your_business")}</h3>   
                        <p className='text'>{t("we_help_your_business_description")}</p>
                        <a className='button bt-primary' href='/contact-us'>{t("learn_more")}</a>
                    </article>
                </aside>

                <aside className='itens-center' data-aos="fade-down">
                    <h3>{t("main_video_title")}</h3>
                    <iframe src="https://www.youtube.com/embed/pBPoPhBz_Yo?si=oGbZ9CFkRaZb3m1X"></iframe>
                </aside>
            </section>

            <Footer data-aos="fade-down" />
        </div>
    );
}

export default Home;
