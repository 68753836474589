import React, { useEffect } from "react";
import Header from "../../components/header";
import { useTranslation } from "react-i18next";
import Footer from "../../components/footer";

import img_mirandela from '../../assets/images/mirandela.jpg';
import '../../assets/styles/global.css';
import './styles.css';
import RequestForm from "../../components/RequestForm";
import AOS from 'aos';
import 'aos/dist/aos.css';
import config from "../../config";

function ContactUs(){
    const {t} = useTranslation();

    useEffect(() => {
        AOS.init({
          duration: 1000,
        });
      }, []);

    return(
        <div>
            <Header pageName={t('contact_us')} headerName={t('contact_us')} headerText={t('contact_us')} headerStyle="header-60" buttonName ={t('contact_us')} buttonLink="/contact"/>

            <section>
                <aside className="aside-contact" data-aos="fade-down">
                    <h3>{t('where_you_can_find_us')}</h3>
                    <p>{t('where_you_can_find_us_description')}</p>
                    <article className="article-contact">
                        <div>
                            <img src={img_mirandela} className="img" />
                        </div>
                        <div className="card-contact">
                            <h4>{t('location')}</h4>
                            <ul>
                                <li><a href={`tel:${config.cellPhoneLink}`}><i class="bi bi-telephone-outbound-fill"></i> {config.cellPhone}</a></li>
                                <li><a href={`mailto:${config.emailGeral}`}><i class="bi bi-envelope-at"></i> {config.emailGeral}</a></li>
                                <li><a href={`mailto:${config.emailInfo}`}><i class="bi bi-envelope-at"></i> {config.emailInfo}</a></li>
                                <li><a href={`https://${config.link}`}><i class="bi bi-globe2"></i> {config.link}</a></li>
                            </ul>
                        </div>
                    </article>
                </aside>
                
                <RequestForm />

                <aside data-aos="fade-left">
                    <h3>{t('digital_presence')}</h3>
                    <p>{t('digital_presence_description')}</p>
                    <div className="card-social-media">
                        <span><a href={config.instagramLink} target="_blank"><i class="bi bi-instagram"></i></a></span>
                        <span><a href={config.whatsappLink} target="_blank"><i class="bi bi-whatsapp"></i></a></span>
                        <span><a href={config.facebookLink} target="_blank"><i class="bi bi-facebook"></i></a></span>
                        <span><a href={config.youtubeLink} target="_blank"><i class="bi bi-youtube"></i></a></span>
                        <span><a href={config.linkedinLink} target="_blank"><i class="bi bi-linkedin"></i></a></span>
                        <span><a href={config.githubLink} target="_blank"><i class="bi bi-github"></i></a></span>
                    </div>
                </aside>
            </section>

            <Footer />
        </div>
    )
}

export default ContactUs;