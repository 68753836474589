import React, { useEffect, useState } from "react";

import man_call from "../../assets/images/man-call.jpg";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './styles.css';
import '../../assets/styles/global.css'
import { addContactRequest } from "../../action/api/contact-request";
import Alert from "../alert";
import { useTranslation } from "react-i18next";
import config from '../../config'
import Aos from "aos";


function RequestForm() {

    useEffect(() => {
        Aos.init({
          duration: 1000,
        });
      }, []);

    const [countries, setCountries] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        codeCellPhone: '+351',
        cellPhone: '',
        company: '',
        position: '',
        mensagem: '',
        subscribe: false
    });
    const [result, setResult] = useState(null)

    const { t } = useTranslation()

    useEffect(() => {
        // Chamada à API para buscar os dados dos países
        fetch('https://restcountries.com/v3.1/all')
            .then(response => response.json())
            .then(data => {
                const countryData = data.map(country => ({
                    name: country.name.common,
                    code: country.idd.root ? country.idd.root + (country.idd.suffixes ? country.idd.suffixes[0] : "") : "",
                    flag: country.flag,
                    icon: country.flags.png
                })).filter(country => country.code); // Filtra países que possuem código de chamada
                setCountries(countryData);
            })
            .catch(error => console.error('Error fetching countries:', error));
    }, []);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const validatePhoneNumber = (number) => {
        const phoneNumberPattern = /^[0-9]{9,14}$/; // Ajuste este regex conforme necessário
        return phoneNumberPattern.test(number);
    };

    const sendMessage = async (e) => {
        e.preventDefault();
    
        const fullPhoneNumber = formData.codeCellPhone + formData.cellPhone;
    
        if (!validatePhoneNumber(formData.cellPhone)) {
            alert('Por favor, insira um número de telemóvel válido.');
            return;
        }

        console.log("Enviar pedido")

        addContactRequest(formData)
        .then((data) => {
            setResult(data)
        }).catch((error) => {
            e = {
                code: 500,
                message: 'Houve um erro inesperado'
            }
            setResult(e)
        })
    
    };
    

    const handleCountrySelect = (code) => {
        setFormData({
            ...formData,
            codeCellPhone: code
        });
    };

    return (
        <aside className="aside-form">
            <article className="article-image itens-center" style={{ marginLeft: "-5px" }} data-aos="fade-right">
                <h3>{t('looking_for_partnership')}</h3>
                <p>{t('looking_for_partnership_description')}</p>
                <p><a href={`mailto:${config.emailInfo}`} className="link">{config.emailInfo}</a></p>
                <img src={man_call} alt="Man making a call" />
            </article>
            <article className="article-form" data-aos="fade-left">
                <div className="form">
                    {result == null
                        ?
                            <form onSubmit={sendMessage}>
                                <label>{t('name')}</label>
                                <input
                                    className="input"
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label>{t('email')}</label>
                                <input
                                    className="input"
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                />

                                <label>{t('phone')}</label>
                                <div className="phone-input d-flex">
                                    <div className="dropdown me-2">
                                        <button className="btn btn-secondary dropdown-toggle input" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {formData.codeCellPhone}
                                        </button>
                                        <ul className="dropdown-menu dropdown-scrollable">
                                            {countries.map((country, index) => (
                                                <li key={index}>
                                                    <button 
                                                        className="dropdown-item" 
                                                        type="button" 
                                                        onClick={() => handleCountrySelect(country.code)}
                                                    >
                                                        <img src={country.icon} alt={`${country.name} flag`} style={{ width: '20px', marginRight: '10px' }} />
                                                        {country.name} ({country.code})
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <input
                                        className="input flex-grow-1"
                                        type="tel"
                                        name="cellPhone"
                                        value={formData.cellPhone}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>

                                <label>{t('company')}</label>
                                <input
                                    className="input"
                                    type="text"
                                    name="company"
                                    value={formData.company}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label>{t('position')}</label>
                                <input
                                    className="input"
                                    type="text"
                                    name="position"
                                    value={formData.position}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label>{t('message')}</label>
                                <textarea
                                    className="input"
                                    name="message"
                                    rows={5}
                                    value={formData.message}
                                    onChange={handleInputChange}
                                    required
                                ></textarea>
                                <span>{t('term_use')}</span>
                                <div className="checkbox">
                                    <input
                                        type="checkbox"
                                        id="subscribe"
                                        name="subscribe"
                                        checked={formData.subscribe}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <label htmlFor="subscribe">{t('accept_term_use')}</label>
                                </div>
                                <button className="input" type="submit">{t('send')}</button>
                            </form>
                        :
                            <div className="form-alert">
                                <Alert code={result.code} message={result.message} />
                            </div>
                    }
                </div>
            </article>
        </aside>
    );
}

export default RequestForm;
