import React, { useEffect } from "react";
import './styles.css';
import { useTranslation } from "react-i18next";
import Aos from "aos";

function Stage() {
    const { t } = useTranslation();

    useEffect(() => {
        Aos.init({
          duration: 1000,
        });
      }, []);

    // Array de etapas, onde cada etapa será traduzida
    const stages = [
        { titleKey: "stage.itens.0.title" },
        { titleKey: "stage.itens.1.title" },
        { titleKey: "stage.itens.2.title" },
        { titleKey: "stage.itens.3.title" },
        { titleKey: "stage.itens.4.title" },
        { titleKey: "stage.itens.5.title" }
    ];

    return (
        <div className="aside-stage" data-aos="fade-left">
            <h3>{t('stage.title')}</h3>
            <div className="stage">
                {stages.map((stage, index) => (
                    <div className="card-stage" key={index}>
                        <div className="circle itens-center">
                            <p>{t(stage.titleKey)}</p>
                        </div>
                        <div className="triangle">
                            <span className="vertex-1"></span>
                            <span className="vertex-2"></span>
                            <span className="vertex-3"></span>
                        </div>
                    </div>
                ))}
            </div>
            <hr className="line" />
        </div>
    );
}

export default Stage;
