import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import favicon_logo from '../../assets/images/favicon.png';
import logo from '../../assets/images/logo.jpg';
import video from '../../assets/video/video_header.mp4';
import './styles.css';
import '../../assets/styles/global.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useTranslation } from 'react-i18next';
import AOS from 'aos';



function Header({ pageName, headerName, headerStyle, buttonName, buttonLink, showButton }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSubItensServiceOpen, setIsSubItensServiceOpen] = useState(false);
  const [isSubItensLangOpen, setIsSubItensLangOpen] = useState(false);

  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    configPage();
  }, [])

  const configPage = () => {
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = favicon_logo;

    // Altera o título da página
    document.title = pageName + ' - Solution apoio';
};

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const navigateTo = (path) => {
    navigate(path);
    setIsSidebarOpen(false); // Fechar sidebar após a navegação
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleSubItensService = () => {
    setIsSubItensServiceOpen(!isSubItensServiceOpen);
  };

  const toggleSubItensLang = () => {
    setIsSubItensLangOpen(!isSubItensLangOpen);
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className={'header '+headerStyle} data-aos="fade-down">
      <video autoPlay muted loop className='video_background'>
        <source src={video} type="video/mp4" />
      </video>
      <div className='overlay'>
        <div className='top-bar'>
          <a href="" onClick={(e) => { e.preventDefault(); navigateTo('/home'); }}><img src={logo} alt={t('solution-apoio')} className='logo' /></a>
          <ul className='nav-menu'>
            <li><a href="" onClick={(e) => { e.preventDefault(); navigateTo('/home'); }}>{t('home')}</a></li>
            <li><a href="" onClick={(e) => { e.preventDefault(); navigateTo('/about-us'); }}>{t('about_us')}</a></li>
            <li className='dropdown'>
              <a href="" onClick={(e) => { e.preventDefault(); navigateTo('/services'); }}>{t('services')}</a>
              <ul className='dropdown-menu'>
                <li><a href="" onClick={(e) => { e.preventDefault(); navigateTo('/services/mobile-app'); }}>{t('application')}</a></li>
                <li><a href="" onClick={(e) => { e.preventDefault(); navigateTo('/services/database'); }}>{t('database')}</a></li>
                <li><a href="" onClick={(e) => { e.preventDefault(); navigateTo('/services/website'); }}>{t('websites')}</a></li>
                {/* <li><a href="" onClick={(e) => { e.preventDefault(); navigateTo('/services/consulting'); }}>{t('consulting')}</a></li>
                <li><a href="" onClick={(e) => { e.preventDefault(); navigateTo('/services/maintenance'); }}>{t('maintenance')}</a></li>
                <li><a href="" onClick={(e) => { e.preventDefault(); navigateTo('/services/security'); }}>{t('security')}</a></li> */}
              </ul>
            </li>
            <li><a href="" onClick={(e) => { e.preventDefault(); navigateTo('/contact-us'); }}>{t('contact_us')}</a></li>
            {/* <li><a href="" onClick={(e) => { e.preventDefault(); navigateTo('/applications'); }}>{t('applications')}</a></li> */}
            <li className='dropdown'>
              <a href=""> <i class="bi bi-translate"></i> {t('language')}</a>
              <ul className='dropdown-menu'>
                <li><a onClick={(e) => { e.preventDefault(); changeLanguage('en'); }}>{t('english')}</a></li>
                <li><a onClick={(e) => { e.preventDefault(); changeLanguage('pt'); }}>{t('portuguese')}</a></li>
              </ul>
            </li>
          </ul>
        </div>
        <div className={`menu-icon ${isSidebarOpen ? 'open' : ''}`} onClick={toggleSidebar}>
          <span className='menu-icon1'></span>
          <span className='menu-icon2'></span>
          <span className='menu-icon3'></span>
        </div>
        <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
          <ul>
            <li><a href="" onClick={(e) => { e.preventDefault(); navigateTo('/home'); }}>{t('home')}</a></li>
            <li><a href="" onClick={(e) => { e.preventDefault(); navigateTo('/about-us'); }}>{t('about_us')}</a></li>
            <li>
              <a href="" onClick={toggleSubItensService}>{t('services')} <div className='icon-service'><span className={`icon-service1 ${isSubItensServiceOpen ? 'open' : ''}`}></span><span className={`icon-service2 ${isSubItensServiceOpen ? 'open' : ''}`}></span></div></a>
              <ul className={`sub-items ${isSubItensServiceOpen ? 'open' : ''}`}>
                <li><a href="" onClick={(e) => { e.preventDefault(); navigateTo('/services/mobile-app'); }}>{t('application')}</a></li>
                <li><a href="" onClick={(e) => { e.preventDefault(); navigateTo('/services/database'); }}>{t('database')}</a></li>
                <li><a href="" onClick={(e) => { e.preventDefault(); navigateTo('/services/website'); }}>{t('websites')}</a></li>
                {/* <li><a href="#ct" onClick={(e) => { e.preventDefault(); navigateTo('/services/consulting'); }}>{t('consulting')}</a></li>
                <li><a href="#mst" onClick={(e) => { e.preventDefault(); navigateTo('/services/maintenance'); }}>{t('maintenance')}</a></li>
                <li><a href="#sc" onClick={(e) => { e.preventDefault(); navigateTo('/services/security'); }}>{t('security')}</a></li> */}
              </ul>
            </li>
            <li><a href="" onClick={(e) => { e.preventDefault(); navigateTo('/contact-us'); }}>{t('contact_us')}</a></li>
            {/* <li><a href="#applications" onClick={(e) => { e.preventDefault(); navigateTo('/applications'); }}>{t('applications')}</a></li> */}
            <li>
              <a href="" onClick={toggleSubItensLang}> <i class="bi bi-translate"></i> {t('language')} <div className='icon-service'><span className={`icon-service1 ${isSubItensLangOpen ? 'open' : ''}`}></span><span className={`icon-service2 ${isSubItensLangOpen ? 'open' : ''}`}></span></div></a>
              <ul className={`sub-items ${isSubItensLangOpen ? 'open' : ''}`}>
                <li><a href="#en" onClick={(e) => { e.preventDefault(); changeLanguage('en'); }}>{t('english')}<span className="flag-icon flag-icon-us" /></a></li>
                <li><a href="#pt" onClick={(e) => { e.preventDefault(); changeLanguage('pt'); }}>{t('portuguese')}</a></li>
              </ul>
            </li>
          </ul>
        </div>
        <div className='center-content'>
          <div className='container-header'>
            <p>{t('project_name')}!</p>
            <h1>{headerName}</h1>
          </div>
          {showButton != null && (
            <a className='button bt-primary' onClick={(e) => { e.preventDefault(); navigateTo(buttonLink); }}>{buttonName}</a>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
