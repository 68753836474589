import React, { useEffect } from "react"
import '../../assets/styles/global.css';
import './styles.css';
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useTranslation } from 'react-i18next';
import about_us from '../../assets/images/about-us.jpg'
import company from '../../assets/images/company.jpg'
import jumara from "../../assets/images/logo-javascript.png"
import entrepreneurs from "../../assets/images/entrepreneurs.jpg"
import smallMediumBusiness from "../../assets/images/small_medium_business.jpg"
import AOS from 'aos';
import 'aos/dist/aos.css';


function About_Us(){
    const { t } = useTranslation();

    useEffect(() => {
        AOS.init({
          duration: 1000, // Define a duração da animação
        });
      }, []);


    return(
        <div>
            <Header pageName={t('about_us')} headerName={t('about_us')} headerText={t('about_us')} headerStyle="header-60" buttonName ={t('contact_us')} buttonLink="/contact"/>
                
            <section>
                <aside className="who-we-are" data-aos="fade-down">
                    <h3>{t('about_us')}</h3>
                    <img src={about_us} className="img"  />
                </aside>
                <aside className="history">
                    <h3 data-aos="fade-down">{t('who_we_are.title')}</h3>
                    <article>
                        <img src={company} style={{width: '400px'}} className="img" data-aos="fade-right"/>
                        <div data-aos="fade-left">
                            <p>{t('who_we_are.description.0.text')}</p>
                            <p>{t('who_we_are.description.0.text')}</p>
                            <p>{t('who_we_are.description.0.text')}</p>
                        </div>
                    </article>
                </aside>

                <aside className="mission-vision-value" data-aos="fade-left">
                    <div>
                        <article>
                            <h3>{t('vision')}</h3>
                            <p>{t('vision_description')}</p>
                        </article>
                        <hr />
                        <article>
                            <h3>{t('mission')}</h3>
                            <p>{t('mission_description')}</p>
                        </article>
                        <hr />
                        <article>
                            <h3>{t('values')}</h3>
                            <ul>
                                <li>{t('values_description.0')}</li>
                                <li>{t('values_description.1')}</li>
                                <li>{t('values_description.2')}</li>
                                <li>{t('values_description.3')}</li>
                                <li>{t('values_description.4')}</li>
                            </ul>

                        </article>
                    </div>
                </aside>
                <aside data-aos="fade-down">
                    <h3>{t('target_audience')}</h3>
                    <article className="article-card">
                        <div className="card">
                            <div className="card-title">
                                <h5 className="text-center">{t('small_medium_business')}</h5>
                            </div>
                            <div className="card-header">
                                <img src={smallMediumBusiness} />
                            </div>
                            <div className="card-text">
                                <p>{t('small_medium_business_description')}</p>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-title">
                                <h5 className="text-center">{t('entrepreneurs')}</h5>
                            </div>
                            <div className="card-header">
                                <img src={entrepreneurs} />
                            </div>
                            <div className="card-text">
                                <p>{t('entrepreneurs_description')}</p>
                            </div>
                        </div>
                    </article>
                </aside>
            </section>

            <Footer />
        </div>
    )
}

export default About_Us