import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../pages/home";
import Page404 from "../pages/page404";
import About_Us from "../pages/about-us";
import Services from "../pages/services";
import Application from "../pages/Application";
import ContactUs from "../pages/contact";
import Websites from "../pages/websites";
import Database from "../pages/database";
import '../assets/styles/global.css'


function AppRoutes() {
    return (    
        <BrowserRouter>
            <Routes>
                <Route index element={<Home />} />
                <Route path="/home" element={<Home />}/>
                <Route path="/about-us" element={<About_Us />} />
                <Route path="/services" element={<Services />} />
                <Route path="/services/website" element={<Websites />} />
                <Route path="/services/mobile-app" element={<Application />} />
                <Route path="/services/database" element={<Database />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="*" element={<Page404 />} />
            </Routes>
        </BrowserRouter>
    );
}

export default AppRoutes;