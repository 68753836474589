import Aos from "aos";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/header";
import Footer from "../../components/footer";
import LogoCarousel from "../../components/logo-carrosel";
import RequestForm from "../../components/RequestForm";
import Stage from "../../components/stage";
import '../../assets/styles/global.css'
import appExample from "../../assets/images/app-example.jpg";
import androidStudioLogo from '../../assets/images/logo-android-studio.png';
import mongoLogo from '../../assets/images/logo-mongodb.png';
import fastAPILogo from '../../assets/images/logo-fastapi.png';
import firebaseLogo from '../../assets/images/logo-firebase.png';
import javascriptLogo from '../../assets/images/logo-javascript.png';
import mysqlLogo from '../../assets/images/logo-mysql.png';
import phpLogo from '../../assets/images/logo-php.png';
import sqlServerLogo from '../../assets/images/logo-sql-server.png';
import reactLogo from '../../assets/images/logo-reactjs.png';
import whatIsWebsite from "../../assets/images/what_is_website.png";
import ServiceDetails from "../../components/service-details";
import talk_to_specialist from "../../assets/images/talk_with_specialist.jpg";
import './styles.css';
import CardInline from "../../components/card-inline";
import config from "../../config";


function Websites() {

    const {t} = useTranslation();

    const logos = [
        { src: androidStudioLogo, alt: 'React Logo' },
        { src: mongoLogo, alt: 'React Logo' },
        { src: fastAPILogo, alt: 'React Logo' },
        { src: firebaseLogo, alt: 'React Logo' },
        { src: javascriptLogo, alt: 'React Logo' },
        { src: mysqlLogo, alt: 'React Logo' },
        { src: phpLogo, alt: 'React Logo' },
        { src: sqlServerLogo, alt: 'React Logo' },
        { src: reactLogo, alt: 'React Logo' },
    ];

    const cardInline = [
        {
            title: t('complete_support.title'),
            description: t('complete_support.description')
        },
        {
            title: t('custom_development.title'),
            description: t('custom_development.description')
        },

    ]
    

    useEffect(() => {
        Aos.init({
          duration: 1000, // Define a duração da animação
        });
      }, []);

    return(
        <div>
            <Header pageName={t('websites')} headerName={t('websites')} headerText={t('websites')} headerStyle="header-60" buttonName ={t('contact_us')} buttonLink="/contact"/>
            <section>
                <ServiceDetails explandServiceTile={t('website_explanation.title')} explandServiceDescription={t('website_explanation.description')} serviceNeedsTitle={t('custom_website_support.title')} serviceNeedsDescription0={t('custom_website_support.description.0.text')} serviceNeedsDescription1={t('custom_website_support.description.1.text')} imageBg={whatIsWebsite}/>
            </section>


            <section>
                <CardInline card={cardInline} />
            </section>

            <section className="background-primary text-white padding-50">
                <h3 className="text-center">{t('complete_support_details.title')}</h3>
                <aside className="display-flex display-block-1000">
                    <article className="margin-50">
                        <img src={talk_to_specialist}  className="img"/>
                    </article>
                    <article className="margin-50 text-justify">
                        <p className="text-justify">{t('complete_support_details.description.title')}</p>
                        <ul className="margin-bottom-40 margin-top-40">
                            <li><strong>{t('complete_support_details.description.itens.0.title')}</strong> {t('complete_support_details.description.itens.0.description')}</li>
                            <li><strong>{t('complete_support_details.description.itens.1.title')}</strong> {t('complete_support_details.description.itens.1.description')}</li>
                            <li><strong>{t('complete_support_details.description.itens.2.title')}</strong> {t('complete_support_details.description.itens.2.description')}</li>
                            <li><strong>{t('complete_support_details.description.itens.3.title')}</strong> {t('complete_support_details.description.itens.3.description')}</li>
                        </ul>
                        <a href={config.whatsappLink} target="_blank" className='button bt-primary width-100'>{t('complete_support_details.description.talk_with_specialist')}</a>
                    </article>
                </aside>
            </section>

            <section>
                <LogoCarousel logos={logos}  />
            </section>

            <section>
                <RequestForm />
            </section>
            
            <section>
                <Stage />
            </section>

            <Footer />
        </div>
    )
}

export default Websites;