import React, {useEffect} from 'react';
import './styles.css'; 
import { useTranslation } from 'react-i18next';
import Header from '../../components/header';
import Footer from '../../components/footer';
import '../../assets/styles/global.css';

function Page404() {
    const { t } = useTranslation();

    return (
        <div className="App">    
            <Header pageName={t('page404')} headerName={t('page404')} headerText={t('page404')} headerStyle="header-100" buttonName ={t('home')} buttonLink="/home"/>
        </div>
    );
}

export default Page404;
