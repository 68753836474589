import React from "react";
import Footer from "../../components/footer";
import { useTranslation } from "react-i18next";
import Header from "../../components/header";
import '../../assets/styles/global.css';
import './styles.css';
import Card from "../../components/card";
import img from "../../assets/images/logo-javascript.png"
import RequestForm from "../../components/RequestForm";
import LogoCarousel from "../../components/logo-carrosel";
import Stage from "../../components/stage";
import appExample from "../../assets/images/app-example.jpg";
import androidStudioLogo from '../../assets/images/logo-android-studio.png';
import mongoLogo from '../../assets/images/logo-mongodb.png';
import fastAPILogo from '../../assets/images/logo-fastapi.png';
import firebaseLogo from '../../assets/images/logo-firebase.png';
import javascriptLogo from '../../assets/images/logo-javascript.png';
import mysqlLogo from '../../assets/images/logo-mysql.png';
import phpLogo from '../../assets/images/logo-php.png';
import sqlServerLogo from '../../assets/images/logo-sql-server.png';
import reactLogo from '../../assets/images/logo-reactjs.png';
import ServiceDetails from "../../components/service-details";
import androidApp from "../../assets/images/android-app.jpg"
import iosApp from "../../assets/images/ios-app.jpg"
import crossPlatFormApp from "../../assets/images/cross-platform-app.jpg"
import whatIsMobileApp  from "../../assets/images/what_is_mobile_app.png"
import '../../assets/styles/global.css'

function Application() {
    const {t} = useTranslation();

    const typesApp = [
        {headerText: '', img: androidApp, altImg: t('app_types.itens.0.title'), textTitle: t('app_types.itens.0.title'), text: t('app_types.itens.0.description'), imgStyle: {borderTopLeftRadius: "10px", borderTopRightRadius: "10px"}},
        {headerText: '', img: iosApp, altImg: t('app_types.itens.1.title'), textTitle: t('app_types.itens.1.title'), text: t('app_types.itens.1.description'), imgStyle: {borderTopLeftRadius: "10px", borderTopRightRadius: "10px"}},
        {headerText: '', img: crossPlatFormApp, altImg: t('app_types.itens.2.title'), textTitle: t('app_types.itens.2.title'), text: t('app_types.itens.2.description'), imgStyle: {borderTopLeftRadius: "10px", borderTopRightRadius: "10px"}}
    ]

    const logos = [
        { src: androidStudioLogo, alt: 'React Logo' },
        { src: mongoLogo, alt: 'React Logo' },
        { src: fastAPILogo, alt: 'React Logo' },
        { src: firebaseLogo, alt: 'React Logo' },
        { src: javascriptLogo, alt: 'React Logo' },
        { src: mysqlLogo, alt: 'React Logo' },
        { src: phpLogo, alt: 'React Logo' },
        { src: sqlServerLogo, alt: 'React Logo' },
        { src: reactLogo, alt: 'React Logo' },
        // Adicione outros logos aqui
    ];
    return(
        <div>
            <Header pageName={t('mobile_apps')} headerName={t('mobile_apps')} headerText={t('mobile_apps')} headerStyle="header-60" buttonName ={t('contact_us')} buttonLink="/contact"/>
            
            <section>
                <ServiceDetails explandServiceTile={t('mobile_apps_explanation.title')} explandServiceDescription={t('mobile_apps_explanation.description')} serviceNeedsTitle={t('mobile_apps_needs.title')} serviceNeedsDescription0={t('mobile_apps_needs.description.0.text')} serviceNeedsDescription1={t('custom_website_support.description.1.text')} imageBg={whatIsMobileApp}/>
            </section>
            <section>
                
                <aside>
                    <Card cardTitle={t('app_types.title')} typesApp={typesApp} />
                </aside>
                <aside>
                    <LogoCarousel logos={logos}  />
                </aside>
                <aside>
                    <RequestForm />
                </aside>
                <aside>
                    <Stage />
                </aside>
            </section>

            <Footer />
        </div>
    )
}

export default Application;