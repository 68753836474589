
import React, { useState } from "react";
import './styles.css'

function Alert({code, message}) {

    return(
        <div className="container">
            <div className={`alert ${code == 200 ? 'alert-success' : 'alert-danger'}`}>
                <p text>Message {message}</p>
            </div>
        </div>
    )
}

export default Alert;