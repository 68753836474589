import React, { useEffect } from "react";
import logo from '../../assets/images/logo.jpg';
import './styles.css';
import { useTranslation } from "react-i18next";
import config from '../../config';
import Aos from "aos";

function Footer() {

    const {t} = useTranslation();
    const year = new Date().getFullYear();

    useEffect(() => {
        Aos.init({
          duration: 1000,
        });
      }, []);


    return(
        <footer data-aos="fade-down">
            <div className="footer">
                <aside>
                    <img src={logo} />
                    <p>{t('footer_text')}</p>
                </aside>
                <aside>
                    <h3>{t('services')}</h3>
                    <ul>
                        <li><a href="/services/website">{t('websites')}</a></li>
                        <li><a href="/services/mobile-app">{t('mobile_apps')}</a></li>
                        <li><a href="/services/database">{t('database')}</a></li>
                        <li><a href="/services">{t('consulting')}</a></li>
                        <li><a href="/services">{t('technical_support_maintenance')}</a></li>
                        <li><a href="/services">{t('cybersecurity')}</a></li>
                    </ul>
                </aside>
                <aside>
                    <h3>{t('find_us')}</h3>
                    <ul className="contact">
                        <li><a href={`tel:+${config.cellPhoneLink}`}><i class="bi bi-telephone-outbound-fill"></i> {config.cellPhone}</a></li>
                        <li><a href={`mailto:${config.emailGeral}`}><i class="bi bi-envelope-at"></i> {config.emailGeral}</a></li>
                    </ul>
                    <ul className="social-media">
                        <li><a target="_blank" href={config.facebookLink}><i class="bi bi-facebook"></i></a></li>
                        <li><a target="_blank" href={config.youtubeLink}><i class="bi bi-youtube"></i></a></li>
                        <li><a target="_blank" href={config.linkedinLink}><i class="bi bi-linkedin"></i></a></li>
                        <li><a target="_blank" href={config.instagramLink}><i class="bi bi-instagram"></i></a></li>
                        <li><a target="_blank" href={config.githubLink}><i class="bi bi-github"></i></a></li>
                    </ul>
                </aside>
            </div>
            <div className="copyright">
                <p>{year} &copy; {t('project_name')}</p>
            </div>
        </footer>
    )    
}

export default Footer;